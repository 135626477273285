<template>
  <LaporanManajemenEksternalForm mode="Tambah" module="Laporan Manajemen"> </LaporanManajemenEksternalForm>
</template>

<script>
import LaporanManajemenEksternalForm from './form';

const LaporanManajemenEksternalAdd = {
  name: 'LaporanManajemenEksternalAdd',
  components: { LaporanManajemenEksternalForm },
};

export default LaporanManajemenEksternalAdd;
</script>
